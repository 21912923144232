@import "~antd/dist/antd.min.css";

.App {
  text-align: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.85);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App .transaction-table tbody td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: small;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App .ant-layout-header {
  background-color: #0000ff;
}

.App .ant-layout-sider-trigger {
  background-color: #0000ff;
}

.App .ant-table-thead .ant-table-cell {
  background-color: #0000ff;
  border-color: #0000ff;
  color: #ffffff;
  border: #0000ff !important;
}

.App
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):before {
  display: none;
}

.App
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):after {
  content: "";
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-form-item-control-input-content {
  text-align: left !important;
}
